import React from 'react'
import Constructions from './Constructions'


function AvailableProjects() {
    return (
        <div>
            <Constructions />
        </div>
    )
}

export default AvailableProjects