
export const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
};

export const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
};

export const validateNotEmpty = (value) => {
    return value.trim() !== '';
};
export const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/; 
    return phoneRegex.test(phoneNumber);
}